import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  categories: [],
};

export const categorySlice = createSlice({
  name: "categories",
  initialState,
  reducers: {
    setCategories: (state, action) => {
      // state.categories = [
      //   ...state.categories,
      //   ...action.payload.filter(
      //     (category) =>
      //       !state.categories.some(
      //         (existingCategory) =>
      //           existingCategory.category_id === category.category_id
      //       )
      //   ),
      // ];
      state.categories = action.payload;
    },
  },
});

export const { setCategories } = categorySlice.actions;
export default categorySlice.reducer;
