import { useState } from "react";
import InputField from "components/fields/InputField";
import { useToast } from "components/Toast/Toaster";
import ApiService from "apis/apiService";
import { useLocation, useNavigate } from "react-router-dom";

export default function ForgetPassword() {
  // hooks
  const location = useLocation();
  const navigate = useNavigate();
  const { showToast } = useToast();
  // states
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [query, setQuery] = useState(location?.search);
  const [loading, setLoading] = useState(false);
  // functions
  const handleVerifyUser = async () => {
    if (!username) {
      showToast("error", "Please enter a username");
    } else {
      try {
        setLoading(true);
        let payload = {
          username: username,
        };
        const response = await ApiService.post(
          "api/auth/resetPasswordLink",
          payload
        );
        if (response?.success) {
          showToast("success", response?.message);
          setUsername("");
        } else {
          showToast("error", response?.message);
        }
        setLoading(false);
      } catch (error) {
        console.log("[Networ Error]", error);
        showToast("error", error.message);
        setLoading(false);
      }
    }
  };
  const handleChangePassword = async () => {
    if (!password) {
      showToast("error", "Password must be provided");
    } else {
      try {
        setLoading(true);
        let payload = {
          resetToken: location?.search.split("?")[1],
          password: password,
        };
        const response = await ApiService.post(
          "api/auth/forgotPassword",
          payload
        );
        if (response?.success) {
          showToast("success", response?.message);
          setUsername("");
          setPassword("");
          setQuery("");
          navigate("/auth/login", { replace: true });
        } else {
          showToast("error", response?.message);
        }
        setLoading(false);
      } catch (error) {
        console.log("[Networ Error]", error);
        showToast("error", error.message);
        setLoading(false);
      }
    }
  };

  return (
    <div className="mb-16 mt-16 flex h-full w-full items-center justify-center px-2 md:mx-0 md:px-0 lg:mb-10 lg:items-center">
      <div className="mt-[10vh] w-full max-w-full flex-col items-center md:pl-4 lg:pl-0 xl:max-w-[420px]">
        <h4 className="mb-2.5 text-4xl font-bold text-navy-700 dark:text-white">
          Forgot Password
        </h4>
        {console.log({
          query: query,
        })}
        {query !== "" ? (
          <InputField
            variant="auth"
            extra="mb-3"
            label="New Password*"
            placeholder="Please enter your new password"
            type="text"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        ) : (
          <InputField
            variant="auth"
            extra="mb-3"
            label="Username*"
            placeholder="Please enter your username"
            type="text"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
        )}
        <button
          disabled={loading}
          onClick={(e) => {
            if (query !== "") {
              handleChangePassword();
            } else {
              handleVerifyUser();
            }
          }}
          className="linear mt-2 w-full rounded-xl bg-brand-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
        >
          {loading
            ? "loading"
            : query !== ""
            ? "Change Password"
            : "Sent reset password link"}
        </button>
      </div>
    </div>
  );
}
