import { useEffect, useState } from "react";
import ApiService from "apis/apiService";
import { useToast } from "components/Toast/Toaster";
import { useSelector } from "react-redux";
import { Select } from "@chakra-ui/react";

const SiteSetting = () => {

    const [loading, setLoading] = useState(false);
    const { showToast } = useToast();
    const token = useSelector((state) => state.auth.token);
    const [errors, setErrors] = useState({})
    const [formData, setformData] = useState({
        ai: '',
        open_api_key: '',
        murf_api_key: ""
    });
    const handleChange = (e) => {
        const { name, value } = e.target
        setErrors('')
        setformData({
            ...formData,
            [name]: value,
        })
        setErrors({
            ...errors,
            [name]: null,
        })
    }

    const validation = (values) => {
        const validationErrors = {}
        if (!values.open_api_key) {
            validationErrors.open_api_key = 'OpenAI API Key is required'
        }
        if (!values.murf_api_key) {
            validationErrors.murf_api_key = 'MurfAI API Key is required'
        }

        return validationErrors
    }
    const getDetails = async () => {
        try {
            setLoading(true);
            const response = await ApiService.post("api/comman_setting/details", {}, token);
            const new_obj = {
                ai: response.link_details.ai,
                murf_api_key: response.link_details.murf_api_key,
                open_api_key: response.link_details.open_api_key,

            }
            setformData(new_obj)
            if (response?.success) {
                showToast("success", response?.message);
            } else {
                showToast("error", response?.message);
            }
            setLoading(false);
        } catch (error) {
            showToast("error", error.message);
            setLoading(false);
        }
    }
    const handleSubmit = async (e) => {
        e.preventDefault()
        let errordisplay = validation(formData)
        if (Object.keys(errordisplay).length > 0) {
            setErrors(errordisplay)
        } else {
            try {
                setLoading(true);
                const response = await ApiService.put("api/comman_setting/update/660be6baccbc4880a2050ba6", formData, token);
                if (response?.success) {
                    showToast("success", response?.message);

                } else {
                    showToast("error", response?.message);
                }
                setLoading(false);
                console.log("response", response)
            } catch (error) {
                console.log("[Networ Error]", error);
                showToast("error", error.message);
                setLoading(false);
            }
            console.log("formData", formData)

        }
    }
    useEffect(() => {
        getDetails();
    }, [])

    return (
        <div className="container mx-auto px-4">
            <div className="max-w-xl mt-10">
                <div className="flex flex-col gap-4">
                    <div>
                        <label className="block mb-2">Select AI</label>
                        <Select name='ai' value={formData.ai} onChange={handleChange}
                            style={{ backgroundColor: "#ffff", borderColor: "rgb(211 211 211)" }}>
                            <option value="openai">OpenAI</option>
                            <option value="murfai">MurfAI</option>
                        </Select>
                        {errors.ai && <div className="invalid-feedback" style={{ color: "red" }}>{errors.ai}</div>}
                    </div>
                    <div>
                        <label className="block mb-2">OpenAI API Key</label>
                        {/* {selectedAPI === "OpenAI" && ( */}
                        <input type="text" name='open_api_key' value={formData.open_api_key} onChange={handleChange} placeholder="Enter OpenAI API Key" className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500" />
                        {/* )} */}
                        {errors.open_api_key && <div className="invalid-feedback" style={{ color: "red" }}>{errors.open_api_key}</div>}
                    </div>
                    <div>
                        <label className="block mb-2">MurfAI API Key</label>
                        {/* {selectedAPI === "Murf" && ( */}
                        <input type="text" name='murf_api_key' value={formData.murf_api_key} onChange={handleChange} placeholder="Enter MurfAI API Key" className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500" />
                        {/* )} */}
                        {errors.murf_api_key && <div className="invalid-feedback" style={{ color: "red" }}>{errors.murf_api_key}</div>}
                    </div>
                </div>
                <button
                    className="linear mt-8 w-full rounded-xl bg-brand-900 py-[12px] pt-4 text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
                    onClick={handleSubmit}
                    style={{ width: "14%" }}
                >
                    Save
                </button>
            </div>
        </div>
    );
};

export default SiteSetting;
