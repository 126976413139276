import SignIn from "views/auth/SignIn";

export default function Auth() {
  return (
    <div className="relative float-right h-full min-h-screen w-full !bg-white dark:!bg-navy-900">
      <main
        className={`bg-red flex min-h-screen w-full items-center justify-center`}
      >
        <div className="mb-auto flex w-full items-center justify-center pl-5 pr-5 md:pl-12">
          <SignIn />
        </div>
      </main>
    </div>
  );
}
