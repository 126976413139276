import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  user: null,
  loading: false,
  error: false,
  token: null,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setToken: (state, action) => {
      state.token = action.payload;
    },
    setUser: (state, action) => {
      state.loading = true;
      state.user = action.payload;
      state.error = false;
    },
    removeUser: (state) => {
      state.loading = true;
      state.user = null;
      state.error = false;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setToken, setUser, removeUser } = authSlice.actions;
export default authSlice.reducer;
