import React, { useEffect, useState } from "react";
import { Spinner } from "@chakra-ui/react";
import ArtistCard from "components/card/ArtistCard";
import { useDisclosure } from "@chakra-ui/hooks";
import {
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/modal";
import InputField from "components/fields/InputField";
import { AiFillPlusCircle } from "react-icons/ai";
import { useToast } from "components/Toast/Toaster";
import { useDispatch, useSelector } from "react-redux";
import ApiService from "apis/apiService";
import { setPodcasts } from "redux/slices/podcastSlice";
import { setCategories } from "redux/slices/categorySlice";

const Artists = () => {
  // hooks
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  const token = useSelector((state) => state.auth.token);
  const podcasts = useSelector((state) => state.podcasts.podcasts);
  const categories = useSelector((state) => state.categories.categories);
  const { showToast } = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  // states
  const [podcastName, setPodcastName] = useState("");
  const [podcastDetails, setPodcastDetails] = useState("");
  const [loading, setLoading] = useState(false);
  const [getLoading, setGetLoading] = useState(false);
  const [refetch, setRefetch] = useState(false);

  useEffect(() => {
    getAllCategories();
  }, [refetch]);

  const getAllCategories = async () => {
    try {
      setGetLoading(true);
      const response = await ApiService.get(
        "api/category/get-all-categories?page=1&limit=100&sortBy=category_name",
        token
      );
      if (response?.success) {
        // showToast("success", response?.message);
        dispatch(setCategories(response?.data));
      } else {
        showToast("error", response?.message);
      }
      setGetLoading(false);
    } catch (error) {
      console.log("[Networ Error]", error);
      showToast("error", error.message);
      setGetLoading(false);
    }
  };
  const handleAddCategory = async () => {
    if (!podcastName) {
      showToast("error", "Please enter podcast name");
    } else if (!podcastDetails) {
      showToast("error", "Please enter podcast details");
    } else {
      try {
        setLoading(true);
        let payload = {
          category_name: podcastName,
          category_details: podcastDetails,
          added_by: user?.role,
        };
        const response = await ApiService.post(
          "api/category/add-category",
          payload,
          token
        );
        if (response?.success) {
          showToast("success", response?.message);
          setPodcastName("");
          setPodcastDetails("");
          setRefetch(true);
        } else {
          showToast("error", response?.message);
        }
        setLoading(false);
        onClose();
      } catch (error) {
        console.log("[Networ Error]", error);
        showToast("error", error.message);
        setLoading(false);
      }
    }
  };

  return (
    <div className="mt-3 grid h-full grid-cols-1 gap-5 xl:grid-cols-2 2xl:grid-cols-3">
      <div className="col-span-1 h-fit w-full xl:col-span-1 2xl:col-span-3">
        <div
          className="mb-5 mt-5 flex cursor-pointer items-center text-brand-900"
          onClick={onOpen}
        >
          <AiFillPlusCircle className="dark:text-red-400" size={28} />
          <h4 className="ml-4 text-2xl font-bold text-navy-700 dark:text-white">
            Add New Artist
          </h4>
        </div>
        <div>
          {getLoading ? (
            <div className="flex h-[75vh] w-full items-center justify-center">
              <Spinner size={"xl"} color="blue" />
            </div>
          ) : (
            <div className="grid grid-cols-2 gap-4 md:grid-cols-3">
              {categories?.map((catogory) => (
                <ArtistCard category_item={catogory} setRefetch={setRefetch} />
              ))}
            </div>
          )}
        </div>
      </div>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent className="relative mx-20 self-center overflow-y-auto bg-white pb-8">
          <ModalHeader className="flex h-[100px] w-full items-center justify-between bg-gray-200 px-8 py-6 text-3xl font-bold text-brand-900">
            <p>Add New Artist</p>
          </ModalHeader>
          <ModalBody className="px-4 py-8">
            <InputField
              variant="auth"
              extra="mb-3"
              label="Artist Name*"
              placeholder="Name"
              type="text"
              value={podcastName}
              onChange={(e) => setPodcastName(e.target.value)}
            />
            <InputField
              variant="auth"
              extra="mb-3"
              label="Artist Details*"
              placeholder="Details"
              type="text"
              value={podcastDetails}
              onChange={(e) => setPodcastDetails(e.target.value)}
            />
            <button
              disabled={loading}
              onClick={() => handleAddCategory()}
              className="linear mt-8 w-full rounded-xl bg-brand-900 py-[12px] pt-4 text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
            >
              {loading ? <Spinner /> : "Add Artist"}
            </button>
            <button
              onClick={onClose}
              className="linear mt-2 w-full rounded-xl bg-red-600 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-red-700 active:bg-brand-700 dark:bg-red-400 dark:text-white dark:hover:bg-red-300 dark:active:bg-brand-200"
            >
              {"Cancel"}
            </button>
          </ModalBody>
        </ModalContent>
      </Modal>
    </div>
  );
};

export default Artists;
