import React, { useEffect, useState } from "react";
import { Spinner } from "@chakra-ui/react";
import ArtistCard from "components/card/ArtistCard";
import { useDisclosure } from "@chakra-ui/hooks";
import { useToast } from "components/Toast/Toaster";
import { useDispatch, useSelector } from "react-redux";
import ApiService from "apis/apiService";
import { setCategories } from "redux/slices/categorySlice";
import UserCard from "components/card/UserCard";

const Users = () => {
  // hooks
  const token = useSelector((state) => state.auth.token);
  const { showToast } = useToast();
  // states
  const [users, setUsers] = useState([]);
  const [getLoading, setGetLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);

  useEffect(() => {
    getAllUsers();
  }, [pageNumber]);

  const getAllUsers = async () => {
    try {
      setGetLoading(true);
      const response = await ApiService.get(
        `api/auth/getAllUsers?page=${pageNumber}&limit=20&sortBy=username`,
        token
      );
      console.log({
        "response?.data": response?.data,
        "response?.success": response?.success,
      });
      if (response?.success) {
        // showToast("success", response?.message);
        setUsers(response?.data);
        setTimeout(() => {
          setGetLoading(false);
        }, 1500);
      } else {
        showToast("error", response?.message);
        setGetLoading(false);
      }
    } catch (error) {
      console.log("[Networ Error]", error);
      showToast("error", error.message);
      setGetLoading(false);
    }
  };

  return (
    <div className="mt-3 grid h-full grid-cols-1 gap-5 xl:grid-cols-2 2xl:grid-cols-3">
      <div className="col-span-1 h-fit w-full xl:col-span-1 2xl:col-span-3">
        <div>
          {getLoading ? (
            <div className="flex h-[75vh] w-full items-center justify-center">
              <Spinner size={"xl"} color="blue" />
            </div>
          ) : (
            <div className="grid grid-cols-2 gap-4 md:grid-cols-3">
              {users && users.length > 0
                ? users?.map((user) => <UserCard user_item={user} />)
                : null}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Users;
