import { useEffect, useState } from "react";
import ApiService from "apis/apiService";
import { useToast } from "components/Toast/Toaster";
import { useSelector } from "react-redux";

const AdsVideo = () => {
    const [bio, setBio] = useState({
        link: ''
    });
    const [loading, setLoading] = useState(false);
    const { showToast } = useToast();
    const token = useSelector((state) => state.auth.token);
    const [errors, setErrors] = useState({})

    const handleBioChange = (e) => {
        const { name, value } = e.target
        setErrors('')
        setBio({
            ...bio,
            [name]: value,
        })
        setErrors({
            ...errors,
            [name]: null,
        })
    }

    const validation = (values) => {
        const validationErrors = {}
        if (!values.link) {
            validationErrors.link = 'Link is required'
        }

        return validationErrors
    }
    const getDetails = async () => {
        try {
            setLoading(true);
            const response = await ApiService.post("api/update-file/details_link", {}, token);
            const new_obj = {
                link: response.link_details.link 

            }
            setBio(new_obj)
            if (response?.success) {
                showToast("success", response?.message);
            } else {
                showToast("error", response?.message);
            }
            setLoading(false);
        } catch (error) {
            showToast("error", error.message);
            setLoading(false);
        }
    }
    const handleSubmit = async (e) => {
        e.preventDefault()
        let errordisplay = validation(bio)
        if (Object.keys(errordisplay).length > 0) {
            setErrors(errordisplay)
        } else {
            try {
                setLoading(true);
                const response = await ApiService.put("api/update-file/edit_link/65e94f3d4424083976a7e108", bio, token);
                if (response?.success) {
                    showToast("success", response?.message);

                } else {
                    showToast("error", response?.message);
                }
                setLoading(false);
                console.log("response", response)
            } catch (error) {
                console.log("[Networ Error]", error);
                showToast("error", error.message);
                setLoading(false);
            }
        }
    }
    useEffect(() => {
        getDetails();
    }, [])

    return (
        <div className="flex w-full flex-col gap-5">
            <div className="w-ful mt-3 flex h-fit flex-col gap-5 lg:grid lg:grid-cols-12">
                <div className="col-span-12 lg:!mb-0">
                    <textarea style={{ marginBottom: "-22px" }}
                        className="w-full h-40 p-3 border border-gray-300 rounded-md resize-none"
                        placeholder="Text"
                        value={bio.link}
                        name="link"
                        onChange={handleBioChange}
                    />

                </div>
            </div>
            {errors.link && <div className="invalid-feedback" style={{ color: "red", marginBottom: "-41px" }}>{errors.link}</div>}
            <button
                className="linear mt-8 w-full rounded-xl bg-brand-900 py-[12px] pt-4 text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
                type="submit"
                onClick={handleSubmit}
                style={{ width: "7%" }}
            > Save
            </button>
        </div>
    );
};

export default AdsVideo;
