import { BsTrash2 } from "react-icons/bs";
import Card from "components/card";
import {
  Badge,
  Spinner,
  Text,
  Tooltip,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import {
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/modal";
import { useSelector } from "react-redux";
import { useState } from "react";
import ApiService from "apis/apiService";
import { useToast } from "components/Toast/Toaster";
import { MdEdit } from "react-icons/md";
import InputField from "components/fields/InputField";

const ArtistCard = ({ category_item, setRefetch }) => {
  const { showToast } = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const token = useSelector((state) => state.auth.token);
  // states
  const [loading, setLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [podcastName, setPodcastName] = useState(category_item?.category_name);
  const [podcastDetails, setPodcastDetails] = useState(
    category_item?.category_details
  );

  const handleEditPodcast = async () => {
    if (!podcastName) {
      showToast("error", "Please enter podcast name");
    } else if (!podcastDetails) {
      showToast("error", "Please enter podcast details");
    } else {
      try {
        setLoading(true);
        let payload = {
          category_name: podcastName,
          category_details: podcastDetails,
        };
        const response = await ApiService.put(
          `api/category/edit-category/${category_item?.category_id}`,
          payload,
          token
        );
        if (response?.success) {
          showToast("success", response?.message);
          setPodcastName("");
          setPodcastDetails("");
          setRefetch(true);
        } else {
          showToast("error", response?.message);
        }
        setLoading(false);
        onClose();
      } catch (error) {
        console.log("[Networ Error]", error);
        showToast("error", error.message);
        setLoading(false);
      }
    }
  };
  const handlePodcastDelete = async () => {
    try {
      setDeleteLoading(true);
      const response = await ApiService.delete(
        `api/category/delete-category/${category_item?.category_id}`,
        token
      );
      if (response?.success) {
        showToast("success", response?.message);
        setRefetch(true);
      } else {
        showToast("error", response?.message);
      }
      setDeleteLoading(false);
    } catch (error) {
      console.log("[Networ Error]", error);
      showToast("error", error.message);
      setDeleteLoading(false);
    }
  };

  return (
    <>
      <Card
        extra={`flex flex-col w-full h-[120px] !p-4 3xl:p-![18px] bg-white`}
      >
        <div className="h-full w-full">
          <div className="mb-2">
            <p className="truncate text-lg font-bold text-navy-700 dark:text-white">
              {category_item?.category_name}
            </p>
            <div className="flex items-center gap-2">
              <Badge variant="subtle" colorScheme="purple">
                By {category_item?.added_by}
              </Badge>
            </div>
          </div>
          <div className="flex items-center justify-center md:flex-col md:items-start lg:flex-row lg:justify-center xl:flex-col 2xl:items-start 3xl:flex-row 3xl:items-center 3xl:justify-end">
            <Tooltip label="Edit">
              <Text
                onClick={() => onOpen()}
                textTransform={"uppercase"}
                color={"white"}
                fontWeight={600}
                fontSize={"sm"}
                p={2}
                alignSelf={"flex-start"}
                rounded={"md"}
                mr={4}
                className="bg-brand-400 hover:bg-brand-500"
              >
                <MdEdit />
              </Text>
            </Tooltip>
            <Tooltip label="Delete">
              <Text
                onClick={() => handlePodcastDelete()}
                textTransform={"uppercase"}
                color={"white"}
                fontWeight={600}
                fontSize={"sm"}
                bg={useColorModeValue("red.400", "red.400")}
                p={2}
                alignSelf={"flex-start"}
                rounded={"md"}
                mr={0}
                className="hover:bg-red-500"
              >
                {deleteLoading ? <Spinner size={"sm"} /> : <BsTrash2 />}
              </Text>
            </Tooltip>
          </div>
        </div>
      </Card>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent className="relative mx-20 self-center overflow-y-auto bg-white pb-8">
          <ModalHeader className="flex h-[100px] w-full items-center justify-between bg-gray-200 px-8 py-6 text-3xl font-bold text-brand-900">
            <p>Edit Artist</p>
          </ModalHeader>
          <ModalBody className="px-4 py-8">
            <InputField
              variant="auth"
              extra="mb-3"
              label="Podcast Name*"
              placeholder="Name"
              type="text"
              value={podcastName}
              onChange={(e) => setPodcastName(e.target.value)}
            />
            <InputField
              variant="auth"
              extra="mb-3"
              label="Podcast Details*"
              placeholder="Details"
              type="text"
              value={podcastDetails}
              onChange={(e) => setPodcastDetails(e.target.value)}
            />
            <button
              onClick={() => handleEditPodcast()}
              disabled={loading}
              className="linear mt-8 w-full rounded-xl bg-brand-900 py-[12px] pt-4 text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
            >
              {loading ? <Spinner /> : "Edit Artist"}
            </button>
            <button
              onClick={onClose}
              className="linear mt-2 w-full rounded-xl bg-red-600 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-red-700 active:bg-brand-700 dark:bg-red-400 dark:text-white dark:hover:bg-red-300 dark:active:bg-brand-200"
            >
              {"Cancel"}
            </button>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ArtistCard;
