import React, { useEffect, useState } from "react";
import { Box, Select, Spinner } from "@chakra-ui/react";
import PodcastCard from "components/card/PodcastCard";
import { useDisclosure } from "@chakra-ui/hooks";
import {
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/modal";
import InputField from "components/fields/InputField";
import { AiFillPlusCircle } from "react-icons/ai";
import { useToast } from "components/Toast/Toaster";
import { useDispatch, useSelector } from "react-redux";
import ApiService from "apis/apiService";
import { setPodcasts } from "redux/slices/podcastSlice";
import { setCategories } from "redux/slices/categorySlice";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'

const Podcasts = () => {
  // hooks
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  const token = useSelector((state) => state.auth.token);
  const podcasts = useSelector((state) => state.podcasts.podcasts);
  const categories = useSelector((state) => state.categories.categories);
  const { showToast } = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  // states
  const [podcastName, setPodcastName] = useState("");
  const [podcastDetails, setPodcastDetails] = useState("");
  const [podcastText, setPodcastText] = useState("");
  const [category, setCategory] = useState("");
  const [image, setImage] = useState("");
  const [imageURL, setImageURL] = useState("");
  const [voice, setVoice] = useState("");
  const [loading, setLoading] = useState(false);
  const [getLoading, setGetLoading] = useState(false);
  const [imageLoading, setImageLoading] = useState(false);
  const [refetch, setRefetch] = useState(false);
  const [edit, setEdit] = useState(false);

  useEffect(() => {
    getAllPodcasts();
  }, [refetch]);

  useEffect(() => {
    getAllCategories();
  }, []);

  const getAllCategories = async () => {
    try {
      setGetLoading(true);
      const response = await ApiService.get(
        "api/category/get-all-categories?page=1&limit=20&sortBy=category_name",
        token
      );
      if (response?.success) {
        // showToast("success", response?.message);
        dispatch(setCategories(response?.data));
      } else {
        showToast("error", response?.message);
      }
      setGetLoading(false);
    } catch (error) {
      console.log("[Networ Error]", error);
      showToast("error", error.message);
      setGetLoading(false);
    }
  };
  const getAllPodcasts = async () => {
    try {
      setGetLoading(true);
      const response = await ApiService.get(
        "api/podcasts/get-all-podcasts",
        token
      );
      if (response?.success) {
        // showToast("success", response?.message);
        dispatch(setPodcasts(response?.data));
        setRefetch(false);
      } else {
        showToast("error", response?.message);
      }
      setGetLoading(false);
    } catch (error) {
      console.log("[Networ Error]", error);
      showToast("error", error.message);
      setGetLoading(false);
    }
  };
  const voices = [
    { id: "alloy", name: "Alloy" },
    { id: "echo", name: "Echo" },
    { id: "fable", name: "Fable" },
    { id: "onyx", name: "Onyx" },
    { id: "nova", name: "Nova" },
    { id: "shimmer", name: "Shimmer" }
  ];
  const handleAddCategory = async () => {
    if (!podcastName) {
      showToast("error", "Please enter podcast name");
    } else if (!podcastDetails) {
      showToast("error", "Please enter podcast details");
    } else if (!podcastText) {
      showToast("error", "Please enter podcast text");
    } else if (category === "" && category === "Select category") {
      showToast("error", "Please select podcast category");
    } else {
      try {
        setLoading(true);
        let payload = {
          podcast_name: podcastName,
          podcast_details: podcastDetails,
          podcast_text: podcastText,
          podcast_image: imageURL,
          podcast_category: category,
          podcast_voice: voice,
          added_by: user?.role,
        };
        const response = await ApiService.post("api/podcasts/add-podcast", payload, token);
        if (response?.success) {
          showToast("success", response?.message);
          setPodcastName("");
          setPodcastDetails("");
          setPodcastText("");
          setImageURL("");
          setImage("");
          setCategory("Select category");
          setVoice("Select voice");
          setRefetch(true);
        } else {
          showToast("error", response?.message);
        }
        setLoading(false);
        onClose();
      } catch (error) {
        console.log("[Networ Error]", error);
        showToast("error", error.message);
        setLoading(false);
      }
    }
  };
  const handleImageUpload = async (image) => {
    if (!image) {
      showToast("error", "No image file selected");
    } else {
      try {
        let formData = new FormData();
        formData.append("file", image);
        setImageLoading(true);
        const response = await ApiService.post(
          "api/file-upload/upload",
          formData,
          token
        );
        if (response?.success) {
          setImageURL(response?.data?.url);
        } else {
          showToast("error", response?.message);
        }
        setImageLoading(false);
      } catch (error) {
        console.log("[Networ Error]", error);
        showToast("error", error.message);
        setImageLoading(false);
      }
    }
  };

  return (
    <div className="mt-3 grid h-full grid-cols-1 gap-5 xl:grid-cols-2 2xl:grid-cols-3">
      <div className="col-span-1 h-fit w-full xl:col-span-1 2xl:col-span-3">
        <div
          className="mb-5 mt-5 flex cursor-pointer items-center text-brand-900"
          onClick={onOpen}
        >
          <AiFillPlusCircle className="dark:text-red-400" size={28} />
          <h4 className="ml-4 text-2xl font-bold text-navy-700 dark:text-white">
            Add New Podcast
          </h4>
        </div>
        <div>
          {getLoading ? (
            <div className="flex h-[75vh] w-full items-center justify-center">
              <Spinner size={"xl"} color="blue" />
            </div>
          ) : (
            <div className="grid grid-cols-2 gap-4 md:grid-cols-3">
              {podcasts?.map((podcast) => (
                <PodcastCard
                  podcast_id={podcast?.podcast_id}
                  title={podcast?.podcast_name}
                  cover_image={podcast?.podcast_image}
                  author={podcast?.added_by}
                  details={podcast?.podcast_details}
                  podcastvoice={podcast?.podcast_voice}
                  podcastText={podcast?.podcast_text}
                  podcast_category_id={podcast?.podcast_category?._id}
                  setRefetch={setRefetch}
                />
              ))}
            </div>
          )}
        </div>
      </div>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent className="relative mx-20 self-center overflow-y-auto bg-white pb-8">
          <ModalHeader className="flex h-[100px] w-full items-center justify-between bg-gray-200 px-8 py-6 text-3xl font-bold text-brand-900">
            <p>Add New Podcast</p>
          </ModalHeader>
          <ModalBody className="px-4 py-8 overflow-y-auto" style={{ maxHeight: "600px", overflowY: "auto" }}>
            <InputField
              variant="auth"
              extra="mb-3"
              label="Podcast Name*"
              placeholder="Name"
              type="text"
              value={podcastName}
              onChange={(e) => setPodcastName(e.target.value)}
            />
            <InputField
              variant="auth"
              extra="mb-3"
              label="Podcast Details*"
              placeholder="Details"
              type="text"
              value={podcastDetails}
              onChange={(e) => setPodcastDetails(e.target.value)}
            />
            {""}
            <label
              className={
                "ml-1.5 text-sm font-medium text-navy-700 dark:text-white"
              }
            >
              {"Podcast Text*"}
            </label>
            <Box
              style={{ maxHeight: "300px", overflowY: "auto" }}
            >
              <div style={{ marginBottom: "1rem" }}>
                <CKEditor
                  label="Podcast Text*"
                  placeholder="Text"
                  editor={ClassicEditor}
                  data={podcastText}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    setPodcastText(data);
                  }}
                  config={{
                    placeholder: "Text",
                    init: {
                      contentsCss: [
                        `
                      .ck-content::before {
                        content: attr(placeholder);
                        color: #999; /* Adjust the color as needed */
                        position: absolute;
                        pointer-events: none;
                      }
                    `
                      ]
                    }
                  }}
                />
              </div>
            </Box>
            <div style={{ marginBottom: "1rem" }}>
              <label
                className={
                  "ml-1.5 text-sm font-medium text-navy-700 dark:text-white"
                }
              >
                {"Artist*"}
              </label>
              <Select
                className="mt-2"
                placeholder="Select artist"
                onChange={(e) => setCategory(e.target.value)}
              >
                {categories?.length > 0
                  ? categories.map((category) => (
                    <option value={category?.category_id}>
                      {category?.category_name}
                    </option>
                  ))
                  : null}
              </Select>

            </div>
            {""}
            <div style={{ marginBottom: "1rem" }}>
              <label className="ml-1.5 text-sm font-medium text-navy-700 dark:text-white">
                {"Select Voice*"}
              </label>
              <Select
                className="mt-2"
                placeholder="Select voice"
                value={voice}
                onChange={(e) => setVoice(e.target.value)}
              >
                {/* <option value="">Select voice</option> */}
                {voices.map((voice) => (
                  <option key={voice.id} value={voice.id}>
                    {voice.name}
                  </option>
                ))}
              </Select>
            </div>
            {imageLoading && (
              <div className="item-center my-2 flex justify-center">
                <Spinner />
              </div>
            )}
            <InputField
              variant="auth"
              extra="mb-3"
              label="Podcast cover image*"
              placeholder="Text"
              type="file"
              value={image}
              onChange={(e) => {
                setImage(e.target.value);
                if (e.target.value) {
                  handleImageUpload(e.target.files[0]);
                }
              }}
            />
            <button
              disabled={loading}
              onClick={() => handleAddCategory()}
              className="linear mt-8 w-full rounded-xl bg-brand-900 py-[12px] pt-4 text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
            >
              {loading ? <Spinner /> : "Add Podcast"}
            </button>
            <button
              onClick={onClose}
              className="linear mt-2 w-full rounded-xl bg-red-600 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-red-700 active:bg-brand-700 dark:bg-red-400 dark:text-white dark:hover:bg-red-300 dark:active:bg-brand-200"
            >
              {"Cancel"}
            </button>
          </ModalBody>
        </ModalContent>
      </Modal>
    </div>
  );
};

export default Podcasts;
