import { BsCheck2, BsTrash2 } from "react-icons/bs";
import Card from "components/card";
import Podcast from "assets/img/podcast.png";
import {
  Badge,
  Box,
  Select,
  Spinner,
  Text,
  Tooltip,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import {
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/modal";
import { useSelector } from "react-redux";
import { useState } from "react";
import ApiService from "apis/apiService";
import { useToast } from "components/Toast/Toaster";
import { MdEdit } from "react-icons/md";
import InputField from "components/fields/InputField";
import { CKEditor } from '@ckeditor/ckeditor5-react'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'

const PodcastCard = ({
  title,
  author,
  details,
  podcastText,
  podcast_id,
  cover_image,
  extra,
  podcastvoice,
  podcast_category_id,
  setRefetch,
}) => {
  const { showToast } = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const token = useSelector((state) => state.auth.token);
  const categories = useSelector((state) => state.categories.categories);
  // function
  const getCategoryNameById = (category_id) => {
    const category = categories.find((c) => c?.category_id === category_id);
    return category ? category?.category_name : "Unknown Category";
  };
  // states
  const [loading, setLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [podcastName, setPodcastName] = useState(title);
  const [podcastDetails, setPodcastDetails] = useState(details);
  const [podcast_Text, setPodcast_Text] = useState(podcastText);
  const [category, setCategory] = useState(podcast_category_id);
  const [voice, setVoice] = useState(podcastvoice);

  const voices = [
    { id: "alloy", name: "Alloy" },
    { id: "echo", name: "Echo" },
    { id: "fable", name: "Fable" },
    { id: "onyx", name: "Onyx" },
    { id: "nova", name: "Nova" },
    { id: "shimmer", name: "Shimmer" }
  ];
  const handleEditPodcast = async () => {
    if (!podcastName) {
      showToast("error", "Please enter podcast name");
    } else if (!podcastDetails) {
      showToast("error", "Please enter podcast details");
    } else if (!podcast_Text) {
      showToast("error", "Please enter podcast text");
    } else if (category === "" && category === "Select category") {
      showToast("error", "Please select podcast category");
    } else {
      try {
        setLoading(true);
        let payload = {
          podcast_name: podcastName,
          podcast_text: podcast_Text,
          podcast_details: podcastDetails,
          podcast_category: category,
          podcast_voice: voice,
        };
        const response = await ApiService.put(
          `api/podcasts/edit-podcast/${podcast_id}`,
          payload,
          token
        );
        if (response?.success) {
          showToast("success", response?.message);
          setPodcastName("");
          setPodcastDetails("");
          setPodcast_Text("");
          setCategory("Select category");
          setVoice("Select voice")
          setRefetch(true);
        } else {
          showToast("error", response?.message);
        }
        setLoading(false);
        onClose();
      } catch (error) {
        console.log("[Networ Error]", error);
        showToast("error", error.message);
        setLoading(false);
      }
    }
  };
  const handlePodcastDelete = async () => {
    try {
      setDeleteLoading(true);
      const response = await ApiService.delete(
        `api/podcasts/delete-podcast/${podcast_id}`,
        token
      );
      if (response?.success) {
        showToast("success", response?.message);
        setRefetch(true);
      } else {
        showToast("error", response?.message);
      }
      setDeleteLoading(false);
    } catch (error) {
      console.log("[Networ Error]", error);
      showToast("error", error.message);
      setDeleteLoading(false);
    }
  };

  return (
    <>
      <Card
        extra={`flex flex-col w-full h-full !p-4 3xl:p-![18px] bg-white ${extra}`}
      >
        <div className="h-full w-full">
          <div className="relative w-full">
            <img
              src={cover_image ? cover_image : Podcast}
              className="mb-3 h-[200px] w-full rounded-xl 3xl:h-[200px] 3xl:w-full"
              alt="cover_image"
            />
            {author === "admin" ? null : (
              <button className="absolute right-3 top-3 flex items-center justify-center rounded-full bg-green-600 p-2 text-brand-500 hover:cursor-pointer">
                <div className="flex h-full  w-full items-center justify-center rounded-full text-xl text-white hover:bg-gray-50 dark:text-navy-900">
                  <BsCheck2 />
                </div>
              </button>
            )}
          </div>
          <div className="mb-2">
            <p className="text-lg font-bold text-navy-700 dark:text-white">
              {title}
            </p>
            <p className="mt-1 text-sm font-medium text-gray-600 md:mt-2">
              By {author}
            </p>
            <Badge variant="subtle" colorScheme="green">
              {getCategoryNameById(podcast_category_id)}
            </Badge>
          </div>
          <div className="flex items-center justify-between md:flex-col md:items-start lg:flex-row lg:justify-between xl:flex-col 2xl:items-start 3xl:flex-row 3xl:items-center 3xl:justify-between">
            <div className="flex">
              <p className="mb-2 text-justify text-sm font-bold text-gray-800 dark:text-white">
                {`${details?.substring(0, 300)}...`}
              </p>
            </div>
          </div>
          <div className="flex items-center justify-center md:flex-col md:items-start lg:flex-row lg:justify-center xl:flex-col 2xl:items-start 3xl:flex-row 3xl:items-center 3xl:justify-end">
            <Tooltip label="Edit">
              <Text
                onClick={() => onOpen()}
                textTransform={"uppercase"}
                color={"white"}
                fontWeight={600}
                fontSize={"sm"}
                p={2}
                alignSelf={"flex-start"}
                rounded={"md"}
                mr={4}
                className="bg-brand-400 hover:bg-brand-500"
              >
                <MdEdit />
              </Text>
            </Tooltip>
            <Tooltip label="Delete">
              <Text
                onClick={() => handlePodcastDelete()}
                textTransform={"uppercase"}
                color={"white"}
                fontWeight={600}
                fontSize={"sm"}
                bg={useColorModeValue("red.400", "red.400")}
                p={2}
                alignSelf={"flex-start"}
                rounded={"md"}
                mr={0}
                className="hover:bg-red-500"
              >
                {deleteLoading ? <Spinner size={"sm"} /> : <BsTrash2 />}
              </Text>
            </Tooltip>
          </div>
        </div>
      </Card>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent className="relative mx-20 self-center overflow-y-auto bg-white pb-8">
          <ModalHeader className="flex h-[100px] w-full items-center justify-between bg-gray-200 px-8 py-6 text-3xl font-bold text-brand-900">
            <p>Edit Podcast</p>
          </ModalHeader>
          <ModalBody className="px-4 py-8 overflow-y-auto" style={{ maxHeight: "600px", overflowY: "auto" }}>
            <InputField
              variant="auth"
              extra="mb-3"
              label="Podcast Name*"
              placeholder="Name"
              type="text"
              value={podcastName}
              onChange={(e) => setPodcastName(e.target.value)}
            />
            <InputField
              variant="auth"
              extra="mb-3"
              label="Podcast Details*"
              placeholder="Details"
              type="text"
              value={podcastDetails}
              onChange={(e) => setPodcastDetails(e.target.value)}
            /> {""}
            <label
              className={
                "ml-1.5 text-sm font-medium text-navy-700 dark:text-white"
              }
            >
              {"Podcast Text*"}
            </label>
            <Box
              style={{ maxHeight: "300px", overflowY: "auto" }}
            >
              <div style={{ marginBottom: "1rem" }}>
                <CKEditor

                  label="Podcast Text*"
                  placeholder="Text"
                  editor={ClassicEditor}
                  data={podcast_Text}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    setPodcast_Text(data);
                  }}
                  config={{
                    placeholder: "Text",
                    styles: `
                ::placeholder {
               color: #fff; /* Adjust the color as needed */
             }
              `,
                  }}
                />
              </div>
            </Box>
            <div style={{ marginBottom: "1rem" }}>
              <label
                className={
                  "ml-1.5 text-sm font-medium text-navy-700 dark:text-white"
                }
              >
                {"Artists*"}
              </label>
              <Select
                className="mt-2"
                placeholder="Select artist"
                value={category}
                onChange={(e) => setCategory(e.target.value)}
              >
                {categories?.length > 0
                  ? categories.map((category) => (
                    <option value={category?.category_id}>
                      {category?.category_name}
                    </option>
                  ))
                  : null}
              </Select>
            </div>
            <div style={{ marginBottom: "1rem" }}>
              <label className="ml-1.5 text-sm font-medium text-navy-700 dark:text-white">
                {"Select Voice*"}
              </label>
              <Select
                className="mt-2"
                placeholder="Select voice"
                value={voice}
                onChange={(e) => setVoice(e.target.value)}
              >
                {/* <option value="">Select voice</option> */}
                {voices.map((voice) => (
                  <option key={voice.id} value={voice.id}>
                    {voice.name}
                  </option>
                ))}
              </Select>
            </div>
            <button
              onClick={() => handleEditPodcast()}
              disabled={loading}
              className="linear mt-8 w-full rounded-xl bg-brand-900 py-[12px] pt-4 text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
            >
              {loading ? <Spinner /> : "Edit Podcast"}
            </button>
            <button
              onClick={onClose}
              className="linear mt-2 w-full rounded-xl bg-red-600 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-red-700 active:bg-brand-700 dark:bg-red-400 dark:text-white dark:hover:bg-red-300 dark:active:bg-brand-200"
            >
              {"Cancel"}
            </button>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default PodcastCard;
