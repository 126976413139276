import React, { useState } from "react";
import avatar from "assets/img/avatar.jpeg";
import banner from "assets/img/profile/banner.png";
import Card from "components/card";
import { useSelector } from "react-redux";
import InputField from "components/fields/InputField";
import { useToast } from "components/Toast/Toaster";
import ApiService from "apis/apiService";

const Banner = () => {
  // hooks
  const { showToast } = useToast();
  const user = useSelector((state) => state.auth.user);
  // states
  const [changePassword, setChangePassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [currentPass, setCurrentPass] = useState("");
  const [newPass, setNewPass] = useState("");
  // functions
  const handlePasswordChange = async () => {
    if (!currentPass) {
      showToast("error", "Please enter old password");
    } else if (!newPass) {
      showToast("error", "Please enter new password");
    } else {
      try {
        setLoading(true);
        let payload = {
          username: user?.username,
          currentPassword: currentPass,
          password: newPass,
        };
        const response = await ApiService.post(
          "api/auth/resetPassword",
          payload
        );

        if (response?.success) {
          showToast("success", response?.message);
        } else {
          showToast("error", response?.message);
        }
        setLoading(false);
        setChangePassword(false);
      } catch (error) {
        console.log("[Networ Error]", error);
        showToast("error", error.message);
        setLoading(false);
      }
    }
  };

  return (
    <Card extra={"items-center w-full h-full p-[16px] bg-cover"}>
      <div
        className="relative mt-1 flex h-32 w-full justify-center rounded-xl bg-cover"
        style={{ backgroundImage: `url(${banner})` }}
      >
        <div className="absolute -bottom-12 flex h-[87px] w-[87px] items-center justify-center rounded-full border-[4px] border-white bg-pink-400 dark:!border-navy-700">
          <img className="h-full w-full rounded-full" src={avatar} alt="" />
        </div>
      </div>
      <div className="mt-16 flex flex-col items-center">
        <h4 className="text-xl font-bold text-navy-700 dark:text-white">
          {user?.email}
        </h4>
        <p className="text-base font-normal text-gray-600">
          {user?.username} ({user?.role}){" "}
        </p>
      </div>
      <div
        className="mt-4 flex cursor-pointer flex-col items-center"
        onClick={() => setChangePassword(!changePassword)}
      >
        <h4 className="text-xl font-bold text-red-500 dark:text-white">
          {changePassword ? "Cancel change" : "Change password"}
        </h4>
      </div>
      {changePassword ? (
        <div className="mb-16 mt-0 flex h-full w-full items-center justify-center px-2 md:mx-0 md:px-0 lg:mb-10 lg:items-center">
          <div className="mt-4 w-full max-w-full flex-col items-center md:pl-4 lg:pl-0 xl:max-w-[420px]">
            <InputField
              variant="auth"
              extra="mb-3"
              label="Current Password*"
              placeholder="******"
              type="password"
              value={currentPass}
              onChange={(e) => setCurrentPass(e.target.value)}
            />
            <InputField
              variant="auth"
              extra="mb-3"
              label="New Password*"
              placeholder="******"
              type="password"
              value={newPass}
              onChange={(e) => setNewPass(e.target.value)}
            />
            <button
              onClick={() => handlePasswordChange()}
              className="linear mt-2 w-full rounded-xl bg-brand-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
            >
              {loading ? "loading" : "Change password"}
            </button>
          </div>
        </div>
      ) : null}
    </Card>
  );
};

export default Banner;
