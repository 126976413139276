import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  podcasts: [],
};

export const podcastSlice = createSlice({
  name: "podcasts",
  initialState,
  reducers: {
    setPodcasts: (state, action) => {
      // state.podcasts = [...state.podcasts, ...action.payload];
      // state.podcasts = state.podcasts.push(action.payload);
      // state.podcasts = [
      //   ...state.podcasts,
      //   ...action.payload.filter(
      //     (newPodcast) =>
      //       !state.podcasts.some(
      //         (existingPodcast) =>
      //           existingPodcast.podcast_id === newPodcast.podcast_id
      //       )
      //   ),
      // ];
      state.podcasts = action.payload;
    },
  },
});

export const { setPodcasts } = podcastSlice.actions;
export default podcastSlice.reducer;
