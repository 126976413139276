import { useState } from "react";
import InputField from "components/fields/InputField";
import { useToast } from "components/Toast/Toaster";
import ApiService from "apis/apiService";
import { useDispatch } from "react-redux";
import { setUser } from "redux/slices/authSlice";
import { setToken } from "redux/slices/authSlice";

export default function SignIn() {
  // hooks
  const { showToast } = useToast();
  const dispatch = useDispatch();
  // states
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  // functions
  const handleLogin = async () => {
    if (!username) {
      showToast("error", "Please enter a username");
    } else if (!password) {
      showToast("error", "Please enter a password");
    } else {
      try {
        setLoading(true);
        let payload = {
          username: username,
          password: password,
        };
        const response = await ApiService.post("api/auth/login", payload);
        if (response?.success) {
          if (response?.user?.role === "admin") {
            showToast("success", response?.message);
            dispatch(setToken(response?.token));
            dispatch(setUser(response?.user));
          } else {
            showToast("error", "Only admins are allowed");
          }
        } else {
          showToast("error", response?.message);
        }
        setLoading(false);
      } catch (error) {
        console.log("[Networ Error]", error);
        showToast("error", error.message);
        setLoading(false);
      }
    }
  };

  return (
    <div className="mb-16 mt-16 flex h-full w-full items-center justify-center px-2 md:mx-0 md:px-0 lg:mb-10 lg:items-center">
      <div className="mt-[10vh] w-full max-w-full flex-col items-center md:pl-4 lg:pl-0 xl:max-w-[420px]">
        <h4 className="mb-2.5 text-4xl font-bold text-navy-700 dark:text-white">
          Sign In
        </h4>
        <p className="mb-9 ml-1 text-base text-gray-600">
          Enter your email and password to sign in!
        </p>
        <InputField
          variant="auth"
          extra="mb-3"
          label="Username*"
          placeholder="admin"
          type="text"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        />
        <InputField
          variant="auth"
          extra="mb-3"
          label="Password*"
          placeholder="Min. 8 characters"
          id="password"
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <div className="mb-4 flex items-center justify-between px-2">
          {/* <div className="flex items-center">
            <Checkbox />
            <p className="ml-2 text-sm font-medium text-navy-700 dark:text-white">
              Keep me logged In
            </p>
          </div> */}
          <div className="flex items-center"></div>
          <a
            className="text-sm font-medium text-brand-500 hover:text-brand-600 dark:text-white"
            href="/auth/forgot-password"
          >
            Forgot Password?
          </a>
        </div>
        <button
          onClick={(e) => {
            // e.preventDefault();
            handleLogin();
          }}
          className="linear mt-2 w-full rounded-xl bg-brand-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
        >
          {loading ? "loading" : "Sign In"}
        </button>
        {/* <div className="mt-4">
          <span className=" text-sm font-medium text-navy-700 dark:text-gray-600">
            Not registered yet?
          </span>
          <a
            href=" "
            className="ml-1 text-sm font-medium text-brand-500 hover:text-brand-600 dark:text-white"
          >
            Create an account
          </a>
        </div> */}
      </div>
    </div>
  );
}
