import { BsTrash2 } from "react-icons/bs";
import Card from "components/card";
import {
  Badge,
  Spinner,
  Text,
  Tooltip,
  useColorModeValue,
} from "@chakra-ui/react";
import { useState } from "react";
import { MdCheck } from "react-icons/md";
import moment from "moment";

const UserCard = ({ user_item }) => {
  // states
  const [deleteLoading, setDeleteLoading] = useState(false);

  // const handlePodcastDelete = async () => {
  //   try {
  //     setDeleteLoading(true);
  //     const response = await ApiService.delete(
  //       `api/category/delete-category/${user_item?.category_id}`,
  //       token
  //     );
  //     if (response?.success) {
  //       showToast("success", response?.message);
  //     } else {
  //       showToast("error", response?.message);
  //     }
  //     setDeleteLoading(false);
  //   } catch (error) {
  //     console.log("[Networ Error]", error);
  //     showToast("error", error.message);
  //     setDeleteLoading(false);
  //   }
  // };

  return (
    <>
      <Card
        extra={`flex flex-col w-full h-[164px] !p-4 3xl:p-![18px] bg-white`}
      >
        <div className="h-full w-full">
          <div className="mb-2">
            <div className="my-2">
              <p className="truncate text-lg font-bold uppercase text-navy-700 dark:text-white">
                {user_item?.user_name}
              </p>
              <p className="truncate text-sm text-gray-500 dark:text-white">
                Email: {user_item?.user_email}
              </p>
              <p className="truncate text-sm text-gray-500 dark:text-white">
                Created on: {moment(user_item?.createdAt).format("lll")}
              </p>
              <p className="truncate text-sm text-gray-500 dark:text-white">
                Activated on: {moment(user_item?.updatedAt).format("lll")}
              </p>
            </div>
            <div className="flex items-center gap-2">
              <Badge variant="subtle" colorScheme="green">
                {user_item?.user_isVerified ? "verfied" : "not verified"}
              </Badge>
              <Badge variant="subtle" colorScheme="yellow">
                {user_item?.user_isActive ? "approved" : "pending"}
              </Badge>
              <Badge variant="subtle" colorScheme="purple">
                {user_item?.user_isPaid ? "paid" : "unpaid"}
              </Badge>
            </div>
          </div>
          {/* <div className="flex items-center justify-center md:flex-col md:items-start lg:flex-row lg:justify-center xl:flex-col 2xl:items-start 3xl:flex-row 3xl:items-center 3xl:justify-end">
            <Tooltip label="Edit">
              <Text
                textTransform={"uppercase"}
                color={"white"}
                fontWeight={600}
                fontSize={"sm"}
                p={2}
                alignSelf={"flex-start"}
                rounded={"md"}
                mr={4}
                className="bg-green-500 hover:bg-green-600"
              >
                <MdCheck />
              </Text>
            </Tooltip>
            <Tooltip label="Delete">
              <Text
                // onClick={() => handlePodcastDelete()}
                textTransform={"uppercase"}
                color={"white"}
                fontWeight={600}
                fontSize={"sm"}
                bg={useColorModeValue("red.400", "red.400")}
                p={2}
                alignSelf={"flex-start"}
                rounded={"md"}
                mr={0}
                className="bg-red-500 hover:bg-red-600"
              >
                {deleteLoading ? <Spinner size={"sm"} /> : <BsTrash2 />}
              </Text>
            </Tooltip>
          </div> */}
        </div>
      </Card>
    </>
  );
};

export default UserCard;
