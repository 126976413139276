import { combineReducers } from "redux";
import authReducer from "./slices/authSlice";
import categoryReducer from "./slices/categorySlice";
import podcastReducer from "./slices/podcastSlice";

export const rootReducer = combineReducers({
  auth: authReducer,
  podcasts: podcastReducer,
  categories: categoryReducer,
});
