import React, { useEffect } from "react";
import {
  Routes,
  Route,
  useNavigate,
  useLocation,
} from "react-router-dom";
// layout
import AdminLayout from "layouts/admin";
import AuthLayout from "layouts/auth";
import { useSelector } from "react-redux";
import ForgetPassword from "views/auth/ForgetPassword";

const App = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const isAuthenticated = useSelector((state) => state.auth.user);

  useEffect(() => {
    if (isAuthenticated) {
      navigate("/admin/dashboard", { replace: true });
    } else {
      if (location?.pathname === "/auth/forgot-password") {
        navigate(
          location?.search
            ? location?.pathname + location?.search
            : location?.pathname,
          {
            state: { query: location.search },
          }
        );
      } else {
        navigate("/auth/login");
      }
    }
  }, [isAuthenticated]);

  return (
    <Routes>
      <Route path="/auth/login" element={<AuthLayout />} />
      <Route path="/auth/forgot-password" element={<ForgetPassword />} />
      <Route
        path="/admin*"
        element={isAuthenticated ? <AdminLayout /> : <AuthLayout />}
      />
      <Route
        path="/"
        element={isAuthenticated ? <AdminLayout /> : <AuthLayout />}
      />
    </Routes>
  );
};

export default App;
