import React from "react";
// Admin Imports
import MainDashboard from "views/admin/default";
import Podcasts from "views/admin/podcasts";
import Profile from "views/admin/profile";
import AdsVideo from "views/admin/AdsVideo";
// Icon Imports
import { MdHome, MdPerson } from "react-icons/md";
import { BiPodcast, BiUserCircle } from "react-icons/bi";
import Artists from "views/admin/artists";
import { RiCommandLine, RiGroup2Line } from "react-icons/ri";
import Users from "views/admin/users";
import SiteSetting from "views/admin/SiteSetting";
import { CiSettings } from "react-icons/ci";

const routes = [
  {
    name: "Dashboard",
    layout: "/admin",
    path: "dashboard",
    icon: <MdHome className="h-6 w-6" />,
    component: <MainDashboard />,
  },
  {
    name: "Podcast",
    layout: "/admin",
    path: "podcast",
    icon: <BiPodcast className="h-6 w-6" />,
    component: <Podcasts />,
    secondary: true,
  },
  {
    name: "Artist",
    layout: "/admin",
    path: "artists",
    icon: <RiGroup2Line className="h-6 w-6" />,
    component: <Artists />,
    secondary: true,
  },
  {
    name: "Users",
    layout: "/admin",
    path: "users",
    icon: <BiUserCircle className="h-6 w-6" />,
    component: <Users />,
    secondary: true,
  },
  {
    name: "Profile",
    layout: "/admin",
    path: "profile",
    icon: <MdPerson className="h-6 w-6" />,
    component: <Profile />,
  },
  {
    name: "Ads Video",
    layout: "/admin",
    path: "adsvideo",
    icon: <RiCommandLine className="h-6 w-6" />,
    component: <AdsVideo />,
  },
  {
    name: "Setting",
    layout: "/admin",
    path: "seting",
    icon: <CiSettings  className="h-6 w-6" />,
    component: <SiteSetting />,
  },
];

export default routes;
